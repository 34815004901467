* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  .App {
    font-family: var(--font-family2);
    width: 100%;
    min-height: 100vh;
  }
  
  .App .navbar {
    width: 85%;
    max-height: 10vh;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #000000;
    background: #ffffff;
  }
  
  .App .navbar .nav-links {
    font-weight: 700;
    font-size: 18px;
    line-height: 21.04px;
    display: flex;
    justify-content: center;
    list-style: none;
  }
  
  .App .navbar .nav-links>a{
    color: #000000;
    text-decoration: none;
    padding: 1rem 1rem;
  }